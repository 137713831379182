import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { PATH } from '@/constants'
import { onBeforeMount, onUnmounted, ref } from 'vue'
import { getCookie } from '@/utils'
import commonService from '@/services/common.service'
import dayjs from 'dayjs'
import { CHAT_STORE } from '@/store/constants'
import { useStore } from 'vuex'

export const getToken = async () => {
  const CSRFToken = await commonService.getCSRFToken()
  return CSRFToken?.data?.data.token
}

export function useWebSocket(type: 'chatChannel' | 'talkChannel' = 'chatChannel') {
  const connection = ref<WebSocket>()

  const route = useRoute()
  const store = useStore()

  let url = `${process.env.VUE_APP_WS_ENDPOINT}`
  const token = ref()
  onBeforeMount(async () => {
    const CSRFToken = await commonService.getCSRFToken()
    token.value = CSRFToken?.data?.data.token
    openSocket()
  })

  const openSocket = () => {
    const path = PATH[`${route.path.split('/')[1]}`]
    if (!path?.header) return
    window.addEventListener('beforeunload', leaving)

    let headers = { 'access-token': token.value }

    Object.keys(headers).forEach((e, i) => {
      url += `${i == 0 ? '?' : '&'}${e}=${encodeURIComponent(headers[e])}`
    })

    connection.value = new WebSocket(url)

    const objIdentifier = {
      chatChannel: '{"channel":"ChatChannel"}',
      talkChannel: '{"channel":"TalkChannel"}',
    }

    connection.value.onopen = () => {
      connection.value?.send(
        JSON.stringify({
          command: 'subscribe',
          identifier: objIdentifier[type],
        })
      )
    }

    connection.value.onmessage = (mess) => {
      const message = JSON.parse(mess.data)?.message
      store.dispatch(CHAT_STORE.ACTIONS.SET_MESSAGE_SOCKET, message)
    }

    connection.value.onerror = (e) => {
      console.log(e)
    }
  }

  const leaving = () => {
    connection.value?.close()
  }

  onUnmounted(() => {
    leaving()
  })

  onBeforeRouteLeave(() => {
    leaving()
  })

  return { connection }
}
